import React from "react";
import { Link } from "gatsby";

import imgL1Logo from "../../assets/image/png/blockriver_logo.png";
import imgL1LogoWhite from "../../assets/image/png/blockriver_logo.png";
import { Title, Button, Section, Box, Text } from "../../components/Core";


const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
    <div>

      {white ? (
        <img src={imgL1LogoWhite} style = { {height: 50}} alt="BlockRiver Technologies" />
      ) : (
        <img src={imgL1Logo} style = { {height: 50}} alt="BlockRiver Technologies" />
      )}
      
      </div>
    </Link>
  );
};

export default Logo;
